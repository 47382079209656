


















































































































import PlayroomBookingDto from '@/models/PlayroomBookingDto';
import PlayroomBookingOrderDto from '@/models/PlayroomBookingOrderDto';
import Vue, { PropType } from 'vue';
import AdditionalOptionsSummary from '@/components/BookingCardParts/AdditionalOptionsSummary.vue';
import PlayroomBookingAdditionOptionsDto from '@/models/PlayroomBookingAdditionOptionsDto';
import {
  addDays,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  startOfDay,
} from 'date-fns';

export default Vue.extend({
  components: { AdditionalOptionsSummary },
  props: {
    playroom: {
      type: Object as PropType<PlayroomBookingDto>,
      required: true,
    },
    order: {
      type: Object as PropType<PlayroomBookingOrderDto>,
      required: true,
    },
  },
  methods: {
    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },
    getSum(addition: PlayroomBookingAdditionOptionsDto): number {
      let price = Number(addition.priceForOvernight);
      if (addition.isPricePerPiece) {
        price *= Number(addition.unit);
      }
      if (addition.isPricePerTime) {
        price *= this.order.totalDays;
      }
      return price;
    },
  },
  computed: {
    getExtraGuests(): number {
      const countOfGuests = Number(this.order.countOfGuests);
      return countOfGuests >= 2 ? countOfGuests - 2 : 0;
    },
    getSurchargePerPersonPerNight(): number {
      if (this.order.totalDays && this.order.totalDays > 0) {
        return this.getExtraGuests * this.playroom.surchargePerPersonPerNight * this.order.totalDays;
      } else {
        return Number(this.playroom.surchargePerPersonPerNight);
      }
    },
    getSurchargePerPersonPerBooking(): number {
      const count = this.getExtraGuests;
      return count * this.playroom.surchargePerPersonPerBookingForOvernight;
    },
    sumOfDailyPrices(): number {
      // Get dates
      const dates: Date[] = [];
      const checkIn = new Date(this.order.checkInDate + 'T' + this.order.checkInTime);
      // Don't count the last day
      const checkOut = new Date(this.order.checkOutDate + 'T' + this.order.checkOutTime);
      for (var dt = startOfDay(checkIn); dt < startOfDay(checkOut); dt.setDate(dt.getDate() + 1)) {
        dates.push(new Date(dt));
      }
      // Get Price per date
      const prices: number[] = [];
      const isHoliday = (date: Date): boolean => {
        const isoDate = date.toLocaleDateString('en-CA');
        return this.playroom.holidays.includes(isoDate);
      };
      dates.forEach((date) => {
        // Is date a holiday
        if (isHoliday(date)) {
          prices.push(this.playroom.weekPrice.onHoliday);
          return;
        }
        const nextDay = addDays(date, 1); // next day to check for holiday
        if (isHoliday(nextDay)) {
          prices.push(this.playroom.weekPrice.beforeHoliday);
          return;
        }
        // Get normal day price
        if (isMonday(date)) {
          prices.push(this.playroom.weekPrice.monday);
          return;
        }
        if (isTuesday(date)) {
          prices.push(this.playroom.weekPrice.tuesday);
          return;
        }
        if (isWednesday(date)) {
          prices.push(this.playroom.weekPrice.wednesday);
          return;
        }
        if (isThursday(date)) {
          prices.push(this.playroom.weekPrice.thursday);
          return;
        }
        if (isFriday(date)) {
          prices.push(this.playroom.weekPrice.friday);
          return;
        }
        if (isSaturday(date)) {
          prices.push(this.playroom.weekPrice.saturday);
          return;
        }
        if (isSunday(date)) {
          prices.push(this.playroom.weekPrice.sunday);
        }
      });
      // Sum Prices
      return prices.reduce((a, b) => a + Number(b), 0);
    },

    bedTaxAmount(): number {
      if (!this.order.hasDates) {
        return 0;
      }
      if (this.playroom.bedTaxType == 'NoBedTax') {
        return 0;
      }
      if (this.playroom.bedTaxType == 'PerBooking') {
        return Number(this.playroom.bedTax);
      }
      if (this.playroom.bedTaxType == 'PerNight') {
        if (!this.order.hasDates) {
          return 0;
        }
        return Number(this.playroom.bedTax) * this.order.totalDays;
      }
      if (this.playroom.bedTaxType == 'PerGuestPerNight') {
        if (!this.order.hasDates) {
          return 0;
        }
        return Number(this.playroom.bedTax) * this.order.totalDays * Number(this.order.countOfGuests);
      }
      if (this.playroom.bedTaxType == 'PercentageOfPrice') {
        return (Number(this.playroom.bedTax) * this.accomodationCost) / 100;
      }
      return 0;
    },

    accomodationCost(): number {
      let total = 0;
      total += Number(this.sumOfDailyPrices);
      total += Number(this.playroom.baseRentForOvernight);
      total += this.getSurchargePerPersonPerNight;
      total += this.getSurchargePerPersonPerBooking;
      return total;
    },

    totalAmount(): number {
      let total = this.accomodationCost;
      total += this.bedTaxAmount;
      total += this.bookedAdditionalOptions.reduce((sum, addition) => this.getSum(addition) + sum, 0);
      return total;
    },

    bookedAdditionalOptions(): PlayroomBookingAdditionOptionsDto[] {
      return this.order.additions.filter((x) => {
        return x.hasChecked;
      });
    },

    showCheckInOutCondition(): boolean {
      let numCheckInTime =
        Number(this.order.checkInTime.split(':')[0]) + Number(this.order.checkInTime.split(':')[1]) / 60;
      let numCheckOutTime =
        Number(this.order.checkOutTime.split(':')[0]) + Number(this.order.checkOutTime.split(':')[1]) / 60;
      return (
        this.order.checkInTime != '' &&
        this.order.checkOutTime != '' &&
        (this.playroom.overnightCheckInStartAt < numCheckInTime ||
          this.playroom.overnightCheckInEndAt < numCheckOutTime)
      );
    },
  },
});
