


























































import Vue from 'vue';
import CustomerProfileService from '@/services/customers/CustomerProfileService';
import { maxLength, minLength, required, numeric } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import Notification from '@/components/shared/Notification.vue';

class CustomerProfileEditViewModel {
  firstName!: string;
  city!: string;
  country!: string;
  state!: string;
  street!: string;
  zipCode!: string;
  lastName!: string;
  displayName!: string;
  phoneNumber!: string;
  houseNumber!: string;
}

const validations = {
  customerProfile: {
    street: {
      minLength: minLength(3),
      maxLength: maxLength(50),
    },
    zipCode: {
      minLength: minLength(4),
      maxLength: maxLength(5),
      numeric,
    },
    city: {
      minLength: minLength(3),
      maxLength: maxLength(25),
    },
    country: {
      minLength: minLength(3),
    },
    state: {
      minLength: minLength(3),
      maxLength: maxLength(25),
    },
    houseNumber: {
      houseNumber: (value: string) => /^[A-Za-z0-9 ]*$/.test(value),
      maxLength: maxLength(5),
    },
    latitude: {},
    longitude: {},
    firstName: { maxLength: maxLength(50) },
    lastName: { maxLength: maxLength(50) },
    displayName: { required },
    phoneNumber: {},
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  validations,
  components: {
    ValidatedInput,
    AddressForm,
    ValidatedFormCard,
    Notification,
  },
  data() {
    return {
      customerProfile: new CustomerProfileEditViewModel(),
      originalCustomerProfile: new CustomerProfileEditViewModel(),
      ready: false,
      showSuccessNotification: false,
    };
  },
  methods: {
    async save() {
      var response = await CustomerProfileService.save(this.customerProfile);
      if (response.success) {
        this.showSuccessNotification = true;
        this.originalCustomerProfile = { ...this.customerProfile };
        this.$store.state.AuthStoreModule.user.displayName = this.customerProfile.displayName;
        this.$emit('profileSaved');
      }
      return response;
    },

    onDiscardChanges() {
      this.customerProfile = { ...this.originalCustomerProfile };
    },

    loadData() {
      CustomerProfileService.getForEdit().then((res) => {
        this.customerProfile = res.value as CustomerProfileEditViewModel;
        this.originalCustomerProfile = { ...this.customerProfile };
        this.ready = true;
      });
    },
  },

  mounted() {
    this.loadData();
  },
});
