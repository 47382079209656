import ApiService from '@/services/ApiService';

const API_URL = '/api/Customer/CustomerProfile/';

class CustomerProfileService {
  save(customerProfile: any) {
    return ApiService.post(`${API_URL}Edit`, customerProfile);
  }

  getForEdit() {
    return ApiService.get(`${API_URL}Edit`);
  }
}

export default new CustomerProfileService();
