
















































































































import Vue, { PropType } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import { de } from 'date-fns/locale';
import toDate from 'date-fns-tz/toDate';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

export default Vue.extend({
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
      required: true,
    },
  },
  computed: {
    playroomRoomCount(): number {
      return parseFloat(this.order.playroomRoomCount);
    },

    playroomFeatures(): string {
      const features = this.order.playroomFeatures;
      const translationKeys = features.map((translationKey) => this.$t('features.' + translationKey).toString());
      return translationKeys.join(' • ');
    },

    showLastMinuteInfo(): boolean {
      if (this.order.status != 'Pending' && this.order.status != 'Prepared') {
        return false;
      }
      if (this.$store.state.AuthStoreModule.user.role != 'Customer') {
        return false;
      }
      const createdAt = toDate(this.order.orderCreatedAt, { timeZone: 'Europe/Berlin' });
      const start = toDate(this.order.start, { timeZone: 'Europe/Berlin' });
      start.setHours(start.getHours() - 3);
      const isLastMinute = createdAt.getTime() > start.getTime();
      return isLastMinute;
    },
  },
  methods: {
    formatDate(dateString: string): string {
      let date = toDate(dateString, { timeZone: 'Europe/Berlin' });
      date = utcToZonedTime(date, 'Europe/Berlin');
      return format(date, 'Pp', { locale: de, timeZone: 'Europe/Berlin' });
    },
  },
});
