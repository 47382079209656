




















import PlayroomBookingDto from '@/models/PlayroomBookingDto';
import PlayroomBookingOrderDto from '@/models/PlayroomBookingOrderDto';
import AdditionalOptions from '@/components/BookingCardParts/AdditionalOptionsModal.vue';
import Vue, { PropType } from 'vue';
import PlayroomBookingAditionalRentDto from '@/models/PlayroomBookingAditionalRentDto';
import PlayroomBookingAdditionOptionsDto from '@/models/PlayroomBookingAdditionOptionsDto';

export default Vue.extend({
  components: { AdditionalOptions },
  props: {
    playroom: {
      type: Object as PropType<PlayroomBookingDto>,
      required: true,
    },
    order: {
      type: Object as PropType<PlayroomBookingOrderDto>,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    additionOptions() {
      const additionOptions = new Array<PlayroomBookingAdditionOptionsDto>();
      this.playroom.additions.forEach((addition: PlayroomBookingAditionalRentDto) => {
        let additionOption = new PlayroomBookingAdditionOptionsDto();
        additionOption.id = addition.id;
        additionOption.hasChecked = false;
        additionOption.name = addition.name;
        additionOption.unit = 0;
        additionOption.forBookingType = addition.forBookingType;
        additionOption.priceForOvernight = addition.priceForOvernight;
        additionOption.priceForHourly = addition.priceForHourly;
        additionOption.isPricePerPiece = addition.isPricePerPiece;
        additionOption.isPricePerTime = addition.isPricePerTime;
        additionOptions.push(additionOption);
      });
      return additionOptions;
    },
  },

  methods: {
    showAdditionOptions() {
      this.showModal = true;
    },

    onAdditionOptionsOk() {
      this.$emit('onChange', this.additionOptions);
      this.showModal = false;
    },

    onAdditionOptionsCancel() {
      this.showModal = false;
    },
  },
});
