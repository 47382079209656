

















import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import Vue, { PropType } from 'vue';
import PlayroomPublicService from '@/services/public/PublicPlayroomService';

export default Vue.extend({
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  data() {
    return {
      ready: false,
      questions: [],
    };
  },
  methods: {
    loadData() {
      PlayroomPublicService.getQuestions(this.playroom.id).then(
        (response) => {
          this.ready = true;
          this.questions = response.value.questions;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          console.log('Error:', error);
        }
      );
    },
  },
  mounted() {
    this.loadData();
  },
});
