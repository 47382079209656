
































































































import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import Rating from '@/components/playrooms/Rating.vue';
import Vue, { PropType } from 'vue';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export default Vue.extend({
  components: { Rating },
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  computed: {
    averageRating(): string {
      if (!this.playroom.ratingDetails.averageRating) {
        return '0';
      }
      return parseFloat(this.playroom.ratingDetails.averageRating).toLocaleString();
    },
    hostRating(): number {
      return parseFloat(this.playroom.ratingDetails.averageHostRating);
    },
    comfortRating(): number {
      return parseFloat(this.playroom.ratingDetails.averageComfortRating);
    },
    priceRating(): number {
      return parseFloat(this.playroom.ratingDetails.averagePriceRating);
    },
    cleanlinessRating(): number {
      return parseFloat(this.playroom.ratingDetails.averageCleanlinessRating);
    },
    locationRating(): number {
      return parseFloat(this.playroom.ratingDetails.averageLocationRating);
    },
    equipmentRating(): number {
      return parseFloat(this.playroom.ratingDetails.averageEquipmentRating);
    },
  },
  methods: {
    ratingTranslationKey(rating: string): string {
      if (rating === null) {
        return '';
      }
      const averageRating = parseFloat(rating);
      if (averageRating >= 4.5) {
        return 'rating45';
      }
      if (averageRating >= 4) {
        return 'rating40';
      }
      if (averageRating >= 3.5) {
        return 'rating35';
      }
      if (averageRating >= 3) {
        return 'rating30';
      }
      return '';
    },
    formatDate(date: string): string {
      return format(new Date(date), 'P', { locale: de });
    },
    body(text: string): string {
      if (text.length < 120) {
        return text;
      } else {
        return text.substring(0, 120) + '...';
      }
    },
  },
});
