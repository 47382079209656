var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"mt10"},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('components.BookingCard.BookingTypePart.hourlyImpInfo')))]),_c('ul',[(!_vm.isCancellationHour && _vm.isCancellationFree && !isNaN(_vm.hoursToBook))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('components.BookingCard.BookingTypePart.cancellationFreeDays', { cancellationTime: _vm.cancellationTime }))+" ")]):_vm._e(),(_vm.isCancellationHour && _vm.isCancellationFree && !isNaN(_vm.hoursToBook))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('components.BookingCard.BookingTypePart.cancellationFreeHours', { cancellationTime: _vm.cancellationTime }))+" ")]):_vm._e(),(
                !_vm.isCancellationHour &&
                !_vm.isCancellationFree &&
                _vm.cancellationPercent >= 0 &&
                _vm.cancellationPercent <= 100 &&
                !isNaN(_vm.hoursToBook)
              )?_c('li',[_vm._v(" "+_vm._s(_vm.$t('components.BookingCard.BookingTypePart.cancellationPercentDays', { cancellationTime: _vm.cancellationTime, percent: _vm.cancellationPercent, }))+" ")]):_vm._e(),(
                _vm.isCancellationHour &&
                !_vm.isCancellationFree &&
                _vm.cancellationPercent >= 0 &&
                _vm.cancellationPercent <= 100 &&
                !isNaN(_vm.hoursToBook)
              )?_c('li',[_vm._v(" "+_vm._s(_vm.$t('components.BookingCard.BookingTypePart.cancellationPercentHours', { cancellationTime: _vm.cancellationTime, percent: _vm.cancellationPercent, }))+" ")]):_vm._e(),(_vm.prepayment > 0)?_c('li',[_vm._v(" "+_vm._s(_vm.$t('components.BookingCard.BookingTypePart.prepayment', { prepayment: _vm.prepaymentLocalString, }))+" ")]):_vm._e(),(_vm.playroom.deposit > 0)?_c('li',[_vm._v(" "+_vm._s(_vm.$t('components.BookingCard.BookingTypePart.deposit', { deposit: _vm.formatCurrency(_vm.playroom.deposit), }))+" ")]):_vm._e()])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }