













import Vue, { PropType } from 'vue';
import { minValue, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import PlayroomBookingDto from '@/models/PlayroomBookingDto';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';

export default Vue.extend({
  components: { ValidatedInput },
  validations() {
    return {
      countOfGuests: {
        required,
        minValue: minValue(2),
      },
    };
  },
  mixins: [validationMixin],
  props: {
    playroom: Object as PropType<PlayroomBookingDto>,
  },
  data() {
    return {
      countOfGuests: 2,
    };
  },
  watch: {
    countOfGuests: {
      immediate: true,
      handler(value: string) {
        this.$emit('onChange', Number(value));
      },
    },
  },
});
