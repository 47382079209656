



















































































import Vue from 'vue';
import BookingCardBookingTypePart from '@/components/BookingCardParts/BookingCardBookingTypePart.vue';
import BookingCardHourlyBookingPart from '@/components/BookingCardParts/BookingCardHourlyBookingPart.vue';
import BookingCardOvernightBookingPart from '@/components/BookingCardParts/BookingCardOvernightBookingPart.vue';
import BookingCardGuestPart from '@/components/BookingCardParts/BookingCardGuestPart.vue';
import BookingCardOvernightCalculationPart from '@/components/BookingCardParts/BookingCardOvernightCalculationPart.vue';
import BookingCardHourlyCalculationPart from '@/components/BookingCardParts/BookingCardHourlyCalculationPart.vue';
import BookingCardAdditionalOptionsPart from '@/components/BookingCardParts/BookingCardAdditionalOptionsPart.vue';
import BookingCardButtonPart from '@/components/BookingCardParts/BookingCardButtonPart.vue';
import BookingCardImportantInformationPart from '@/components/BookingCardParts/BookingCardImportantInformationPart.vue';
import BookingCardConfirmationModal from '@/components/BookingCardParts/BookingCardConfirmationModal.vue';
import PublicPlayroomService from '@/services/public/PublicPlayroomService';
import PlayroomBookingDto from '@/models/PlayroomBookingDto';
import PlayroomBookingOrderDto from '@/models/PlayroomBookingOrderDto';
import CustomerOrderService from '@/services/customers/CustomerOrderService';
import PlayroomBookingAdditionOptionsDto from '@/models/PlayroomBookingAdditionOptionsDto';
import LoginModal from '@/components/shared/LoginModal.vue';
import CustomerProfileForm from '@/components/profile/CustomerProfileForm.vue';

export default Vue.extend({
  components: {
    BookingCardHourlyBookingPart,
    BookingCardBookingTypePart,
    BookingCardOvernightBookingPart,
    BookingCardGuestPart,
    BookingCardOvernightCalculationPart,
    BookingCardHourlyCalculationPart,
    BookingCardAdditionalOptionsPart,
    BookingCardButtonPart,
    BookingCardImportantInformationPart,
    BookingCardConfirmationModal,
    LoginModal,
    CustomerProfileForm,
  },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      playroom: new PlayroomBookingDto(),
      order: new PlayroomBookingOrderDto(this.playroomId),
      showConfirmationModal: false,
      orderIdForConfirmationModal: '',
      showLoginModal: false,
      showProfileModal: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    showForOvernight(): boolean {
      return this.order.bookingType == 'overnight' && this.playroom.availableCheckInList.length > 0;
    },
    showForHourly(): boolean {
      return this.order.bookingType == 'hourly' && this.playroom.availableCheckInList.length > 0;
    },
    isNotLoggedIn(): boolean {
      return !this.$store.state.AuthStoreModule.status.loggedIn;
    },
    showAdditionalOptions(): boolean {
      const generalShow = this.showForHourly || this.showForOvernight;
      const additions = this.playroom.additions.filter(
        (a) => a.forBookingType.toLowerCase() == this.order.bookingType.toLowerCase()
      );
      const hasAdditions = additions.length > 0;

      return generalShow && hasAdditions;
    },
  },
  methods: {
    loadData(force = false) {
      if (this.order.checkInDate != '' && this.order.checkInTime != '') {
        const checkIn = this.order.checkInDate + ' ' + this.order.checkInTime;
        PublicPlayroomService.getBookingWithCheckin(this.playroom.id, checkIn, this.order.bookingType).then(
          (response) => {
            this.playroom = response.value;
            if (this.playroom.availableCheckOutList.length == 0) {
            }
            // Set checkin after list is set
            if (this.order.bookingType == 'hourly') {
              this.onCheckOutDateChange(this.order.checkInDate);
            }
          },
          (error: any) => {
            console.log('Error:', error);
          }
        );
      }
      if (force || !this.ready) {
        PublicPlayroomService.getForBooking(this.playroomId, this.order.bookingType).then(
          (response) => {
            this.playroom = response.value;
            this.ready = true;
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: any) => {
            console.log('Error:', error);
          }
        );
      }
    },

    profileSaved() {
      this.showProfileModal = false;
      this.handleSubmit();
    },

    onCheckInDateChange(newCheckInDate: string) {
      if (this.order.checkInDate != newCheckInDate) {
        this.order.checkInDate = newCheckInDate;
        const fullDate = this.playroom.availableCheckInList.find((x) => x.startsWith(this.order.checkInDate));
        if (fullDate) {
          const time = fullDate.split(' ')[1];
          this.order.checkInTime = time;
        } else {
          this.order.checkInTime = '';
        }
        this.loadData();
      }
    },

    onCheckOutDateChange(newCheckOutDate: string) {
      if (this.order.checkOutDate != newCheckOutDate) {
        this.order.checkOutDate = newCheckOutDate;
        const fullDate = this.playroom.availableCheckOutList.find((x) => x.startsWith(this.order.checkOutDate));
        if (fullDate) {
          const time = fullDate.split(' ')[1];
          this.order.checkOutTime = time;
        } else {
          this.order.checkOutTime = '';
        }
        this.loadData();
      }
    },

    onCheckInTimeChange(newCheckInTime: string) {
      if (this.order.checkInTime != newCheckInTime) {
        this.order.checkInTime = newCheckInTime;
        this.loadData();
      }
    },

    onCheckOutTimeChange(newCheckOutTime: string) {
      this.order.checkOutTime = newCheckOutTime;
    },

    onBookingTypeChange(selectedBookingType: string) {
      this.order.bookingType = selectedBookingType;
      this.order.additions = [];
      this.loadData(true);
    },

    onGuestCountChange(newGuestCount: string) {
      this.order.countOfGuests = newGuestCount;
    },

    onAdditionalOptionsChange(newAdditions: PlayroomBookingAdditionOptionsDto[]) {
      this.order.additions = newAdditions;
    },

    onUpdateShow() {
      this.showLoginModal = false;
    },

    handleSubmit(): void {
      if (this.isNotLoggedIn) {
        this.showLoginModal = true;
      } else {
        CustomerOrderService.saveOrder({ ...this.order }).then(
          (resp) => {
            this.orderIdForConfirmationModal = resp.value;
            this.showConfirmationModal = true;
          },
          (resp) => {
            if (resp.includes('NoCustomerProfile')) {
              this.showProfileModal = true;
            }
          }
        );
      }
    },
  },
});
