

























import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';

export default Vue.extend({
  mixins: [validationMixin],
  components: {
    ValidatedCheckbox,
  },
  validations() {
    var vals = {
      acceptBusinessTerms: { checked: (val: boolean) => val || false },
      acceptHouseRules: {},
      acceptPrivacyPolicy: {},
    };
    if (!this.hasHouseRules) {
      vals.acceptHouseRules = { checked: (val: boolean) => val || false };
    }
    if (!this.hasPrivacyPolicy) {
      vals.acceptPrivacyPolicy = { checked: (val: boolean) => val || false };
    }
    return vals;
  },
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
      required: true,
    },
  },
  watch: {
    acceptBusinessTerms() {
      this.emitValues();
    },
    acceptHouseRules() {
      this.emitValues();
    },
    acceptPrivacyPolicy() {
      this.emitValues();
    },
  },
  data() {
    return {
      acceptBusinessTerms: false,
      acceptHouseRules: false,
      acceptPrivacyPolicy: false,
    };
  },
  computed: {
    hasHouseRules(): boolean {
      return !!this.order.houseRulesFileLink;
    },
    hasPrivacyPolicy(): boolean {
      return !!this.order.privacyPolicyFileLink;
    },
  },
  methods: {
    emitValues() {
      this.$emit('onChange', {
        acceptBusinessTerms: this.acceptBusinessTerms,
        acceptHouseRules: this.acceptHouseRules,
        acceptPrivacyPolicy: this.acceptPrivacyPolicy,
      });
    },
  },
});
