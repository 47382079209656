















import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
});
