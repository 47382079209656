


















import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasToolTip(): boolean {
      return this.$te('components.PlayroomDetailPricePart.' + this.name + '.info');
    },
  },
});
