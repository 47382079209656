




























import PlayroomBookingDto from '@/models/PlayroomBookingDto';
import OrderDetailGeneralInformationPart from '@/components/orderDetailParts/OrderDetailGeneralInformationPart.vue';
import OrderDetailPricePart from '@/components/orderDetailParts/OrderDetailPricePart.vue';
import OrderDetailImportantInformationPart from '@/components/orderDetailParts/OrderDetailImportantInformationPart.vue';
import BookingCardPersonalNoticePart from '@/components/BookingCardParts/BookingCardPersonalNoticePart.vue';
import BookingCardConfirmationCheckboxes from '@/components/BookingCardParts/BookingCardConfirmationCheckboxes.vue';
import Vue, { PropType } from 'vue';
import CustomerOrderService from '@/services/customers/CustomerOrderService';
import Notification from '@/components/shared/Notification.vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import de from 'date-fns/locale/de';
import parseISO from 'date-fns/parseISO';
import { isSameDay, format } from 'date-fns';

export default Vue.extend({
  components: {
    OrderDetailGeneralInformationPart,
    OrderDetailPricePart,
    OrderDetailImportantInformationPart,
    BookingCardPersonalNoticePart,
    BookingCardConfirmationCheckboxes,
    Notification,
  },
  props: {
    playroom: {
      type: Object as PropType<PlayroomBookingDto>,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      order: new CustomerOrderDetailDto(),
      showNotification: false,
      acceptBusinessTerms: false,
      acceptHouseRules: false,
      acceptPrivacyPolicy: false,
      loading: false,
    };
  },
  watch: {
    orderId() {
      this.loadData();
    },
  },
  computed: {
    title(): string {
      const id = this.orderId.split('-')[0];
      return this.$t('components.BookingCardConfirmationModal.title', { id }).toString();
    },
    notificationText(): string {
      if (!this.order.start || !this.order.end) {
        return '';
      }
      const checkIn = parseISO(this.order.start);
      const checkOut = parseISO(this.order.end);
      let checkOutFormat = 'PPPP | p'; // date
      if (isSameDay(checkIn, checkOut)) {
        checkOutFormat = 'p'; // just time
      }
      return this.$t('components.BookingCardParts.BookingCardConfirmationModal.notificationText', {
        playroomName: this.playroom.name,
        checkIn: format(checkIn, 'PPPP | p', { locale: de }),
        checkOut: format(checkOut, checkOutFormat, { locale: de }),
      }).toString();
    },
    canConfirm(): boolean {
      // There has to be an order
      if (!this.order) {
        return false;
      }
      // Business Terms are required and always to be checked
      if (!this.acceptBusinessTerms) {
        return false;
      }
      // Privacy Policy are optional for the host. But if they are uploaded they have to be checked too.
      if (!!this.order.privacyPolicyFileLink && !this.acceptPrivacyPolicy) {
        return false;
      }
      // House Rules are optional for the host. But if they are uploaded they have to be checked too.
      if (!!this.order.houseRulesFileLink && !this.acceptHouseRules) {
        return false;
      }
      if (this.loading) {
        return false;
      }
      return true;
    },
  },
  methods: {
    loadData() {
      CustomerOrderService.getDetail(this.orderId).then((res) => {
        this.ready = true;
        this.order = res.value;
      });
    },

    onUpdateShow(newShow: boolean) {
      this.$emit('onUpdateShow', newShow);
    },

    onChangeCheckboxes(values: {
      acceptHouseRules: boolean;
      acceptBusinessTerms: boolean;
      acceptPrivacyPolicy: boolean;
    }) {
      this.acceptBusinessTerms = values.acceptBusinessTerms;
      this.acceptHouseRules = values.acceptHouseRules;
      this.acceptPrivacyPolicy = values.acceptPrivacyPolicy;
    },

    onConfirm() {
      this.loading = true;
      CustomerOrderService.confirmOrder(this.orderId, this.order.personalNotice).then(() => {
        this.showNotification = true;
        this.loading = false;
      });
    },

    handleCloseNotification() {
      this.showNotification = false;
      this.$router.push({ name: 'CustomerOrderDetail', params: { orderId: this.orderId } });
    },
  },
});
