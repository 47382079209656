



































import Vue, { PropType } from 'vue';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import CustomerMessageService from '@/services/customers/CustomerMessageService';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import Notification from '@/components/shared/Notification.vue';

const validations = {
  message: {
    body: {
      required,
      maxLength: maxLength(500),
      minLength: minLength(5),
    },
    subject: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(5),
    },
  },
};

export default Vue.extend({
  components: { ValidatedInput, ValidatedTextArea, Notification },
  validations,
  mixins: [validationMixin],
  data() {
    return {
      message: {
        subject: '',
        body: '',
      },
      showSuccessNotification: false,
    };
  },
  props: {
    playroom: {
      type: Object as PropType<PlayroomDetailDto>,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('onUpdateShow', false);
    },
    onSend() {
      CustomerMessageService.Create(this.message, this.playroom.ownerId).then(() => {
        this.showSuccessNotification = true;
        this.message.body = '';
        this.message.subject = '';
        this.onClose();
      });
    },
  },
});
