import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import ApiService, { GenericServerResponse } from '@/services/ApiService';

const API_URL = '/api/Customer/Order/';

class CustomerOrderService {
  downloadIcs(orderId: string) {
    return ApiService.get(`${API_URL}DownloadIcs?orderId=${orderId}`);
  }

  getConfirmationDetails(orderId: string) {
    return ApiService.get(`${API_URL}Confirmation?orderId=${orderId}`);
  }

  cancelOrder(id: string, message: string | null) {
    return ApiService.post(`${API_URL}CancelOrder?orderId=${id}`, { message });
  }

  withdrawOrder(id: string, message: string | null) {
    return ApiService.post(`${API_URL}WithdrawOrder?orderId=${id}`, { message });
  }

  getDetail(orderId: string): Promise<GenericServerResponse<CustomerOrderDetailDto>> {
    return ApiService.get(`${API_URL}Details?orderId=${orderId}`);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }

  saveOrder(order: {
    countOfGuests: number | string;
    checkInDate: string;
    checkInTime: string;
    checkOutDate: string;
    checkOutTime: string;
  }) {
    order.countOfGuests = String(order.countOfGuests);
    const checkIn = order.checkInDate + ' ' + order.checkInTime;
    const checkOut = order.checkOutDate + ' ' + order.checkOutTime;
    return ApiService.post(API_URL + 'Create', { ...order, checkIn, checkOut });
  }

  confirmOrder(orderId: string, message: string) {
    return ApiService.post(API_URL + 'Confirm', { orderId, message });
  }

  getEmptyOrder(playroomId: string) {
    return ApiService.get(`${API_URL}Create?playroomId=${playroomId}`);
  }

  getInvoice(orderId: string) {
    return ApiService.get(`${API_URL}Invoice?orderId=${orderId}`);
  }

  deleteOrder(orderId: string) {
    return ApiService.delete(`${API_URL}Delete?orderId=${orderId}`);
  }
}

export default new CustomerOrderService();
