






























import Vue, { PropType } from 'vue';
import PlayroomBookingDto from '@/models/PlayroomBookingDto';

export default Vue.extend({
  props: {
    playroom: Object as PropType<PlayroomBookingDto>,
  },
  data() {
    return {
      isOvernightOn: true as boolean | null,
      isHourlyAvailable: true,
      lastEmittedValue: '',
    };
  },
  mounted() {
    this.isOvernightOn = this.playroom.allowOvernightStay;
    this.emitValue();
  },
  methods: {
    emitValue(): void {
      const type = this.isOvernightOn ? 'overnight' : 'hourly';
      if (type != this.lastEmittedValue) {
        this.$emit('onChange', type);
        this.lastEmittedValue = type;
      }
    },

    showOvernight(): void {
      if (this.playroom.allowOvernightStay) {
        this.isOvernightOn = true;
        this.emitValue();
      }
    },

    showHourly(): void {
      if (this.playroom.allowHourBooking) {
        this.isOvernightOn = false;
        this.emitValue();
      }
    },
  },
});
