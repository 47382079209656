export default class PlayroomBookingAdditionOptionsDto {
  id!: string;
  hasChecked = false;
  name!: string;
  additionalRent!: string;
  unit!: number;
  checkInTotalTime!: number;
  forBookingType = 'All';
  priceForOvernight!: string;
  priceForHourly!: string;
  isPricePerPiece!: boolean;
  isPricePerTime!: boolean;
}
