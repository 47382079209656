





































import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import Rating from '@/components/playrooms/Rating.vue';
import PlayroomFavoriteIcon from '@/components/playrooms/PlayroomFavoriteIcon.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  components: { Rating, PlayroomFavoriteIcon },
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  computed: {
    currentUser(): { role: string } {
      return this.$store.state.AuthStoreModule.user;
    },
  },
});
