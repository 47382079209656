





























































































import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import PriceRowComponent from '@/components/playroomDetailParts/PriceRowComponent.vue';
import Vue, { PropType, VueConstructor } from 'vue';
import addMinutes from 'date-fns/addMinutes';
import startOfDay from 'date-fns/startOfDay';
import PlayroomDetailPriceModel from '@/components/playroomDetailParts/PlayroomDetailPriceModel.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  components: { PriceRowComponent, PlayroomDetailPriceModel },
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  data() {
    return {
      bookingType: '',
      showTempModal: false,
    };
  },
  computed: {
    colSize(): number {
      return this.playroom.allowOvernightStay && this.playroom.allowHourBooking ? 6 : 12;
    },
    pricePerHour(): number {
      return parseFloat(this.playroom.pricePerHour);
    },
    baseRentForHourly(): number {
      return parseFloat(this.playroom.baseRentForHourly);
    },
    baseRentForOvernight(): number {
      return parseFloat(this.playroom.baseRentForOvernight);
    },
    surchargePerPersonPerBookingForOvernight(): number {
      return parseFloat(this.playroom.surchargePerPersonPerBookingForOvernight);
    },
    surchargePerPersonPerBookingForHourly(): number {
      return parseFloat(this.playroom.surchargePerPersonPerBookingForHourly);
    },
    surchargePerPersonPerHour(): number {
      return parseFloat(this.playroom.surchargePerPersonPerHour);
    },
    surchargePerPersonPerNight(): number {
      return parseFloat(this.playroom.surchargePerPersonPerNight);
    },
    bedTax(): string {
      switch (this.playroom.bedTaxType) {
        case 'PerBooking':
        case 'PerNight':
        case 'PerGuestPerNight':
          return this.formatCurrency(parseFloat(this.playroom.bedTax));
        case 'PercentageOfPrice':
          return parseFloat(this.playroom.bedTax) + ' %';
        case 'NoBedTax':
        default:
          return '';
      }
    },
    pricePerNight(): number {
      return parseFloat(this.playroom.pricePerNight);
    },
    showBedTax(): boolean {
      return Number(this.playroom.bedTax) != 0 && this.playroom.bedTaxType !== 'NoBedTax';
    },
    checkInStartAt(): string {
      return this.formatTime(this.playroom.overnightCheckInStartAt);
    },
    checkInEndAt(): string {
      return this.formatTime(this.playroom.overnightCheckInEndAt);
    },
    checkOutStartAt(): string {
      return this.formatTime(this.playroom.overnightCheckOutStartAt);
    },
    checkOutEndAt(): string {
      return this.formatTime(this.playroom.overnightCheckOutEndAt);
    },
  },
  methods: {
    formatTime(minutes: number): string {
      const time = addMinutes(startOfDay(new Date()), minutes);
      return this.formatDateTime(time, 'p');
    },
    openPricePopup(bookingType: string) {
      this.bookingType = bookingType;
      this.showTempModal = true;
    },
  },
});
