



























































import Vue, { PropType } from 'vue';
import PlayroomBookingDto from '@/models/PlayroomBookingDto';

export default Vue.extend({
  props: {
    playroom: {
      type: Object as PropType<PlayroomBookingDto>,
      required: true,
    },
  },
  data() {
    return {
      selectedDate: null as Date | null,
      checkInTime: '',
      checkOutTime: '',
      checkOutReady: true,
    };
  },
  watch: {
    playroom: {
      immediate: true,
      handler() {
        this.checkOutReady = true;
      },
    },
    selectedDate: {
      immediate: true,
      handler() {
        if (this.selectedDate) {
          const selectedDate = this.dateToString(this.selectedDate);
          this.checkOutReady = false;
          this.$emit('onCheckInDateChange', selectedDate);
        }
      },
    },
    checkInTimes: {
      handler() {
        if (this.checkInTimes.length > 0) {
          const checkInTimeUnset = this.checkInTime == null || this.checkInTime == '';
          const checkInTimeValid = this.checkInTimes.map((c) => c.value).includes(this.checkInTime);
          if (checkInTimeUnset || !checkInTimeValid) {
            this.checkInTime = this.checkInTimes[0].value;
          }
        }
      },
    },
    checkOutTimes: {
      handler() {
        if (this.checkOutTimes.length > 0) {
          const checkOutTimeUnset = this.checkOutTime == null || this.checkOutTime == '';
          const checkOutTimeValid = this.checkOutTimes.map((c) => c.value).includes(this.checkOutTime);
          if (checkOutTimeUnset || !checkOutTimeValid) {
            this.checkOutTime = this.checkOutTimes[0].value;
          }
          this.checkOutReady = true;
        }
      },
    },
    checkInTime: {
      immediate: true,
      handler() {
        if (this.checkInTime != '') {
          this.checkOutReady = false;
        }
        this.$emit('onCheckInTimeChange', this.checkInTime);
      },
    },
    checkOutTime: {
      immediate: true,
      handler() {
        this.$emit('onCheckOutTimeChange', this.checkOutTime);
      },
    },
  },
  computed: {
    checkOutTimes(): { value: string; label: string }[] {
      if (this.playroom.availableCheckOutList && this.selectedDate) {
        const checkInDate = this.dateToString(this.selectedDate);
        return this.playroom.availableCheckOutList
          .filter((t) => {
            return t.split(' ')[0] == checkInDate;
          })
          .map((dt: string) => ({
            value: dt.split(' ')[1],
            label: dt.split(' ')[1],
          }));
      } else {
        return [];
      }
    },
    showCheckOutTime(): boolean {
      return this.checkOutReady;
    },
    availableDates(): string[] {
      if (this.playroom.availableCheckInList == undefined) {
        return [];
      }
      const hourCheckInStartAt = this.playroom.hourCheckInStartAt / 60;
      const hourCheckInEndAt = this.playroom.hourCheckInEndAt / 60;
      return [
        ...new Set(
          this.playroom.availableCheckInList
            .map((dt: string) => {
              const date = dt.split(' ')[0];

              const hourPart = dt.split(' ')[1];
              let hour = Number(hourPart.split(':')[0]);
              const minutes = hourPart.split(':')[1];
              if (minutes == '30') {
                hour += 0.5;
              }

              // Check for Start Hour
              if (!isNaN(hourCheckInStartAt)) {
                const matchedStartTime = hourCheckInStartAt <= hour;
                if (!matchedStartTime) return null;
              }

              // Check for End Hour
              if (!isNaN(hourCheckInEndAt)) {
                const matchedEndTime = hourCheckInEndAt >= hour;
                if (!matchedEndTime) return null;
              }
              return date;
            })
            // Remove nulls and non unique
            .filter((value, index, self) => value != null && self.indexOf(value) === index) as string[]
        ),
      ];
    },

    checkInDate(): string {
      return this.selectedDate && this.selectedDate != null
        ? this.selectedDate.getFullYear() +
            '-' +
            ('0' + (this.selectedDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + this.selectedDate.getDate()).slice(-2)
        : '';
    },

    checkInTimes(): { value: string; label: string }[] {
      if (this.checkInDate && this.playroom.availableCheckInList != undefined) {
        const hourCheckInStartAt = this.playroom.hourCheckInStartAt / 60;
        const hourCheckInEndAt = this.playroom.hourCheckInEndAt / 60;
        return this.playroom.availableCheckInList
          .filter((t) => {
            // Check for matched Date
            const matchedDate = t.split(' ')[0] == this.checkInDate;
            if (!matchedDate) return false;

            const hourPart = t.split(' ')[1];
            let hour = Number(hourPart.split(':')[0]);
            const minutes = hourPart.split(':')[1];
            if (minutes == '30') {
              hour += 0.5;
            }

            // Check for Start Hour
            if (!isNaN(hourCheckInStartAt)) {
              const matchedStartTime = hourCheckInStartAt <= hour;
              if (!matchedStartTime) return false;
            }

            // Check for End Hour
            if (!isNaN(hourCheckInEndAt)) {
              const matchedEndTime = hourCheckInEndAt >= hour;
              if (!matchedEndTime) return false;
            }
            return true;
          })
          .map((dt: string) => ({
            value: dt.split(' ')[1],
            label: dt.split(' ')[1],
          }));
      } else {
        return [];
      }
    },
  },

  methods: {
    dateToString(dt: Date | string): string {
      return dt && dt instanceof Date
        ? dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2)
        : '';
    },

    openCheckInDate() {
      (this.$refs.calendar as any).showPopover();
    },
  },
});
