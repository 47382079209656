











































































import PlayroomBookingDto from '@/models/PlayroomBookingDto';
import PlayroomBookingOrderDto from '@/models/PlayroomBookingOrderDto';
import Vue, { PropType } from 'vue';
import AdditionalOptionsSummary from '@/components/BookingCardParts/AdditionalOptionsSummary.vue';
import PlayroomBookingAdditionOptionsDto from '@/models/PlayroomBookingAdditionOptionsDto';
import { addDays, isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday, parse } from 'date-fns';

export default Vue.extend({
  components: { AdditionalOptionsSummary },
  props: {
    playroom: {
      type: Object as PropType<PlayroomBookingDto>,
      required: true,
    },
    order: {
      type: Object as PropType<PlayroomBookingOrderDto>,
      required: true,
    },
  },
  methods: {
    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },
    getTotalPriceOfAdditionalOptions(addition: PlayroomBookingAdditionOptionsDto): number {
      let price = Number(addition.priceForHourly);
      if (addition.isPricePerPiece) {
        price *= Number(addition.unit);
      }
      if (addition.isPricePerTime) {
        price *= this.order.totalHours;
      }
      return price;
    },
  },
  computed: {
    getExtraGuests(): number {
      const countOfGuests = Number(this.order.countOfGuests);
      return countOfGuests >= 2 ? countOfGuests - 2 : 0;
    },

    isDateTimesValid(): boolean {
      return this.order.checkInDate !== '' && this.order.checkInTime != '' && this.order.checkOutTime != '';
    },

    getSurchargePerPersonPerHour(): number {
      return this.getExtraGuests * Number(this.playroom.surchargePerPersonPerHour) * this.order.totalHours;
    },

    getSurchargePerPersonPerBooking(): number {
      return this.getExtraGuests * this.playroom.surchargePerPersonPerBookingForHourly;
    },

    basePrice(): number {
      // Get Price per date
      const isHoliday = (holidayDate: Date): boolean => {
        const isoDate = holidayDate.toISOString();
        return this.playroom.holidays && this.playroom.holidays.includes(isoDate);
      };
      const date = parse(this.order.checkInDate, 'yyyy-MM-dd', new Date());
      // Is date a holiday
      if (isHoliday(date)) {
        return this.playroom.weekPrice.onHoliday;
      }
      const dayAfter = addDays(date, 1);
      if (isHoliday(dayAfter)) {
        return this.playroom.weekPrice.beforeHoliday;
      }
      // Get normal day price
      if (isMonday(date)) {
        return this.playroom.weekPrice.monday;
      }
      if (isTuesday(date)) {
        return this.playroom.weekPrice.tuesday;
      }
      if (isWednesday(date)) {
        return this.playroom.weekPrice.wednesday;
      }
      if (isThursday(date)) {
        return this.playroom.weekPrice.thursday;
      }
      if (isFriday(date)) {
        return this.playroom.weekPrice.friday;
      }
      if (isSaturday(date)) {
        return this.playroom.weekPrice.saturday;
      }
      if (isSunday(date)) {
        return this.playroom.weekPrice.sunday;
      }
      return NaN;
    },

    totalPrice(): number {
      return this.basePrice * this.order.totalHours;
    },

    getSummary(): PlayroomBookingAdditionOptionsDto[] {
      return this.order.additions.filter((x) => {
        return x.hasChecked;
      });
    },

    totalAmount(): number {
      let total = 0;
      total += Number(this.totalPrice);
      total += Number(this.playroom.baseRentForHourly);
      total += Number(this.getSurchargePerPersonPerHour);
      total += Number(this.getSurchargePerPersonPerBooking);
      this.getSummary.forEach((x) => {
        total += this.getTotalPriceOfAdditionalOptions(x);
      });
      return total;
    },
  },
});
