












import Vue, { PropType } from 'vue';
import { maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';

const validations = {
  order: {
    personalNotice: {
      maxLength: maxLength(1000),
    },
  },
};

export default Vue.extend({
  components: { ValidatedTextArea },
  validations,
  mixins: [validationMixin],
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
});
