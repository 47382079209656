






















































import Vue from 'vue';
import PublicPlayroomService from '@/services/public/PublicPlayroomService';

class PlayroomPriceDto {
  monday!: number;
  tuesday!: number;
  wednesday!: number;
  thursday!: number;
  friday!: number;
  saturday!: number;
  sunday!: number;
  onHoliday!: number;
  beforeHoliday!: number;
}

export default Vue.extend({
  props: {
    playroomId: {
      type: String,
      required: true,
    },
    bookingType: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      PlayroomPriceDto: {} as PlayroomPriceDto,
    };
  },
  watch: {
    show() {
      if (this.show) {
        this.dataLoad();
      }
    },
  },
  computed: {
    popupTitle(): string {
      if (this.bookingType == 'Overnight') {
        return this.$t('components.PlayroomDetailPricePart.priceDetailTitleOvernight').toString();
      } else {
        return this.$t('components.PlayroomDetailPricePart.priceDetailTitleHourly').toString();
      }
    },
    priceLabel(): string {
      if (this.bookingType == 'Overnight') {
        return this.$t('components.PlayroomDetailPricePart.priceDetailPerOvernight').toString();
      } else {
        return this.$t('components.PlayroomDetailPricePart.priceDetailPerHour').toString();
      }
    },
  },
  methods: {
    dataLoad() {
      PublicPlayroomService.getPrice(this.playroomId, this.bookingType).then((res) => {
        this.PlayroomPriceDto = res.value;
      });
    },
    formatCurrency(price: number) {
      return (
        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price) + ' / ' + this.priceLabel
      );
    },
  },
});
