import PlayroomBookingAditionalRentDto from './PlayroomBookingAditionalRentDto';
import PlayroomBookingPlayroomCancellationRulesDto from './PlayroomBookingPlayroomCancellationRulesDto';

export class WeekPrice {
  monday = 0;
  tuesday = 0;
  wednesday = 0;
  thursday = 0;
  friday = 0;
  saturday = 0;
  sunday = 0;
  beforeHoliday = 0;
  onHoliday = 0;
}

export default class PlayroomBookingDto {
  id!: string;
  allowOvernightStay!: boolean;
  allowHourBooking!: boolean;
  name!: string;

  surchargePerPersonPerBookingForOvernight = 0;
  surchargePerPersonPerBookingForHourly = 0;
  surchargePerPersonPerNight = 0;
  surchargePerPersonPerHour = 0;
  pricePerHour = 0;
  pricePerNight = 0;
  baseRentForOvernight = 0;
  baseRentForHourly = 0;
  acceptsCash!: boolean;
  acceptsCreditCard!: boolean;
  acceptsPaypal!: boolean;
  acceptsInvoice!: boolean;
  otherPayment!: string;
  overnightCheckInStartAt!: number;
  overnightCheckInEndAt!: number;
  overnightCheckOutStartAt!: number;
  overnightCheckOutEndAt!: number;
  hourCheckInStartAt!: number;
  hourCheckInEndAt!: number;
  deposit = 0;
  prepaymentForHourlyBooking = '0';
  prepaymentForOvernightBooking = '0';
  additions = new Array<PlayroomBookingAditionalRentDto>();
  playroomCancellationRules = new Array<PlayroomBookingPlayroomCancellationRulesDto>();
  availableCheckInList = new Array<string>();
  availableCheckOutList = new Array<string>();
  houseRulesFileLink!: string;
  businessTermsFileLink!: string;
  privacyPolicyFileLink!: string;
  holidays = new Array<string>();
  weekPrice = new WeekPrice();
  bedTax = '0';
  bedTaxType = 'PerBooking';
  productVariant = 'Standard';
}
