























































import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import PlayroomAdditionDto from '@/views/customer/orders/PlayroomAdditionDto';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  computed: {
    colSize(): number {
      return this.playroom.allowOvernightStay && this.playroom.allowHourBooking ? 6 : 12;
    },
    sortedAdditions(): PlayroomAdditionDto[] {
      const additions = this.playroom.additions;
      additions.sort((a, b) => (a.forBookingType > b.forBookingType && 1) || -1);
      additions.sort((p) => (p.isPricePerTime ? 0 : 1));
      additions.sort((p) => p.sortOrder);
      return additions;
    },
    sortedAdditionsForHourly(): PlayroomAdditionDto[] {
      return this.sortedAdditions
        .filter((a) => a.forBookingType == 'All' || a.forBookingType == 'Hourly')
        .sort((a, b) => a.sortOrder - b.sortOrder);
    },
    sortedAdditionsForOvernight(): PlayroomAdditionDto[] {
      return this.sortedAdditions
        .filter((a) => a.forBookingType == 'All' || a.forBookingType == 'Overnight')
        .sort((a, b) => a.sortOrder - b.sortOrder);
    },
  },
  methods: {
    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },

    unitType(addition: PlayroomAdditionDto, unit: string): string {
      var unitType = 'Price';
      if (addition.isPricePerPiece) {
        unitType += 'PerPiece';
      }
      if (addition.isPricePerTime) {
        unitType += 'PerTime' + unit;
      }
      return unitType;
    },
  },
});
