

















































import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import Vue, { PropType, VueConstructor } from 'vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  computed: {
    prepaymentForHourlyBooking(): number {
      return parseFloat(this.playroom.prepaymentForHourlyBooking);
    },
    prepaymentForOvernightBooking(): number {
      return parseFloat(this.playroom.prepaymentForOvernightBooking);
    },
    deposit(): number {
      return parseFloat(this.playroom.deposit);
    },
  },
});
