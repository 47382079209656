

























































import Vue, { PropType, VueConstructor } from 'vue';
import PlayroomBookingAdditionOptionsDto from '@/models/PlayroomBookingAdditionOptionsDto';
import PlayroomBookingOrderDto from '@/models/PlayroomBookingOrderDto';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  props: {
    additionOptions: {
      type: Array as PropType<PlayroomBookingAdditionOptionsDto[]>,
      required: true,
    },
    showSummaryTotal: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object as PropType<PlayroomBookingOrderDto>,
      required: true,
    },
  },
  computed: {
    getSummary(): PlayroomBookingAdditionOptionsDto[] {
      return this.additionOptions.filter((x) => {
        return x.hasChecked;
      });
    },
    summaryTotal(): number {
      const reducer = (pv: number, cv: PlayroomBookingAdditionOptionsDto) => this.getSum(cv) + pv;
      return this.getSummary.reduce(reducer, 0);
    },
  },
  methods: {
    getSum(addition: PlayroomBookingAdditionOptionsDto): number {
      let price =
        this.order.bookingType == 'hourly' ? Number(addition.priceForHourly) : Number(addition.priceForOvernight);
      if (addition.isPricePerPiece) {
        price *= Number(addition.unit);
      }
      if (addition.isPricePerTime) {
        const times = this.order.bookingType == 'hourly' ? this.order.totalHours : this.order.totalDays;
        price *= times;
      }
      return price;
    },

    getPrice(additionOption: PlayroomBookingAdditionOptionsDto): number {
      const isHourly = this.order.bookingType == 'hourly';
      const value = isHourly ? additionOption.priceForHourly : additionOption.priceForOvernight;
      return Number(value);
    },

    getSumAdditionalRent(additionalRent: number) {
      return (+additionalRent).toFixed(2);
    },

    getSumPerHour(pricePerHour: number) {
      return (pricePerHour * this.order.totalHours).toFixed(2);
    },

    getSumPricePerHourPerPiece(pricePerHour: number, unit: number) {
      return (pricePerHour * this.order.totalHours * unit).toFixed(2);
    },

    getSumPerOvernight(pricePerpricePerOvernight: number) {
      return (pricePerpricePerOvernight * this.order.totalDays).toFixed(2);
    },

    getSumPricePerOvernightPerPiece(pricePerOvernight: number, unit: number) {
      return (pricePerOvernight * this.order.totalDays * unit).toFixed(2);
    },

    translationKey(addition: PlayroomBookingAdditionOptionsDto, bookingType: string): string {
      let unitType = 'Price';
      if (addition.isPricePerPiece) {
        unitType += 'PerPiece';
      }
      if (addition.isPricePerTime) {
        unitType += 'PerTime' + bookingType;
      }
      return unitType;
    },
  },
});
