import PlayroomAdditionDto from '@/views/customer/orders/PlayroomAdditionDto';

class Rating {
  id!: string;
  date!: string;
  rating!: string;
  message!: string;
  subject!: string;
}

class RatingDetails {
  averageRating!: string;
  ratingCount!: string;
  averageEquipmentRating!: string;
  averageCleanlinessRating!: string;
  averageComfortRating!: string;
  averagePriceRating!: string;
  averageLocationRating!: string;
  averageHostRating!: string;
  exampleRatings!: Rating;
}

class AddressViewModel {
  hideAddress!: boolean;
  latitude!: number;
  longitude!: number;
  addressRadius!: number;
}

class CancellationRule {
  beforeStart!: string;
  percentage!: number;
  cancellationRulesType!: string;
}

export default class PlayroomDetailDto {
  id!: string;
  name!: string;
  addressZipCode!: string;
  addressCity!: string;
  pricePerHour!: string;
  baseRentForHourly!: string;
  baseRentForOvernight!: string;
  surchargePerPersonPerBookingForHourly!: string;
  surchargePerPersonPerBookingForOvernight!: string;
  surchargePerPersonPerHour!: string;
  bedTax!: string;
  areaInSquareMeter!: string;
  bedTaxType!: string;
  pricePerNight!: string;
  surchargePerPersonPerNight!: string;
  additions!: Array<PlayroomAdditionDto>;
  ratingDetails!: RatingDetails;
  address!: AddressViewModel;
  description!: string;
  ownerId!: string;
  houseRules!: string[];
  houseRulesFileLink!: string;
  businessTermsFileLink!: string;
  averageRating!: string;
  ratingCount!: number;
  hourlyCancellationRules!: CancellationRule[];
  overnightCancellationRules!: CancellationRule[];
  prepaymentForOvernightBooking!: string;
  prepaymentForHourlyBooking!: string;
  prepaymentOnlyForNewGuests!: boolean;
  deposit!: string;
  hasSelfCheckIn!: boolean;
  smokingAllowed!: boolean;
  petsAllowed!: boolean;
  partyEventAllowed!: boolean;
  allowOvernightStay!: boolean;
  allowHourBooking!: boolean;
  overnightCheckInStartAt!: number;
  overnightCheckInEndAt!: number;
  overnightCheckOutStartAt!: number;
  overnightCheckOutEndAt!: number;
  ownerDisplayName!: string;
  isFavorite!: boolean;
  files!: PlayroomFileViewModel[];
}

export class PlayroomFileViewModel {
  id!: string;
  playroomId!: string;
  type!: string;
  link!: string;
  fileName!: string;
  dataUrl!: string;
}
