

























































































































import Vue, { PropType } from 'vue';
import PlayroomBookingDto from '@/models/PlayroomBookingDto';
import { validationMixin } from 'vuelidate';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import startOfDay from 'date-fns/startOfDay';

export default Vue.extend({
  mixins: [validationMixin],
  props: {
    playroom: {
      type: Object as PropType<PlayroomBookingDto>,
      required: true,
    },
  },
  data() {
    return {
      selectedCheckInDate: '' as Date | string,
      selectedCheckOutDate: '' as Date | string,
      checkInDate: '',
      checkOutDate: '',
      checkInTime: '',
      checkOutTime: '',
    };
  },
  watch: {
    selectedCheckInDate: {
      immediate: true,
      handler() {
        this.checkInDate = this.dateToString(this.selectedCheckInDate);
        if (this.selectedCheckInDate && this.checkInTime == '') {
          const firstPossibleTime = this.firstPossibleTime(this.checkInDate, this.playroom.availableCheckInList);
          this.checkInTime = firstPossibleTime;
        }
        this.checkOutDate = '';
        this.checkOutTime = '';
        this.selectedCheckOutDate = '';
        this.$emit('onCheckInDateChange', this.checkInDate);
      },
    },
    selectedCheckOutDate: {
      immediate: true,
      handler() {
        this.checkOutDate = this.dateToString(this.selectedCheckOutDate);
        if (this.selectedCheckOutDate && this.checkOutTime == '') {
          const firstPossibleTime = this.firstPossibleTime(this.checkOutDate, this.playroom.availableCheckOutList);
          this.checkOutTime = firstPossibleTime;
        }
        this.$emit('onCheckOutDateChange', this.checkOutDate);
      },
    },
    checkInTime: {
      immediate: true,
      handler() {
        this.$emit('onCheckInTimeChange', this.checkInTime);
      },
    },
    checkOutTime: {
      immediate: true,
      handler() {
        this.$emit('onCheckOutTimeChange', this.checkOutTime);
      },
    },
  },
  computed: {
    isDatesEnter(): boolean {
      return this.checkInDate !== '' && this.checkOutDate !== '';
    },

    checkOutDates(): string[] {
      if (this.playroom.availableCheckOutList) {
        return [
          ...new Set(
            this.playroom.availableCheckOutList.map((dt: string) => {
              return dt.split(' ')[0];
            })
          ),
        ];
      } else {
        return [];
      }
    },

    checkOutTimes(): { value: string; label: string }[] {
      if (this.playroom.availableCheckOutList && this.checkOutDate) {
        return this.playroom.availableCheckOutList
          .filter((t) => {
            return t.split(' ')[0] == this.checkOutDate;
          })
          .map((dt: string) => ({
            value: dt.split(' ')[1],
            label: dt.split(' ')[1],
          }));
      } else {
        return [];
      }
    },

    checkInTimes(): { value: string; label: string }[] {
      if (this.playroom.availableCheckInList && this.checkInDate) {
        return this.playroom.availableCheckInList
          .filter((t) => {
            return t.split(' ')[0] == this.checkInDate;
          })
          .map((dt: string) => ({
            value: dt.split(' ')[1],
            label: dt.split(' ')[1],
          }));
      } else {
        return [];
      }
    },

    checkInDates(): string[] {
      if (this.playroom.availableCheckInList) {
        return [
          ...new Set(
            this.playroom.availableCheckInList.map((dt: string) => {
              return dt.split(' ')[0];
            })
          ),
        ];
      } else {
        return [];
      }
    },

    checkInStartAt(): string {
      return this.formatTime(this.playroom.overnightCheckInStartAt);
    },

    checkInEndAt(): string {
      return this.formatTime(this.playroom.overnightCheckInEndAt);
    },
    checkOutStartAt(): string {
      return this.formatTime(this.playroom.overnightCheckOutStartAt);
    },

    checkOutEndAt(): string {
      return this.formatTime(this.playroom.overnightCheckOutEndAt);
    },

    showCheckInOutMsg(): boolean {
      let showMessage = false;
      // Don't show a warning if no date is selected
      if (this.checkInDate == '') {
        return false;
      }
      if (this.checkInTime) {
        const isInHourRange = this.isInHourRange(this.checkInTime, this.checkInStartAt, this.checkInEndAt);
        showMessage = !isInHourRange;
      }
      if (this.checkOutTime) {
        const isInHourRange = this.isInHourRange(this.checkOutTime, this.checkOutStartAt, this.checkOutEndAt);
        showMessage = showMessage || !isInHourRange;
      }
      return showMessage;
    },
  },

  methods: {
    firstPossibleTime(date: string, list: string[]): string {
      // get first possible time
      const firstPossibleTime = list.find((t) => {
        return t.split(' ')[0] == date;
      });
      if (firstPossibleTime) {
        return firstPossibleTime.split(' ')[1];
      }
      return '00:00';
    },

    isInHourRange(time: string, start: string, end: string): boolean {
      let startHour = Number(start.split(':')[0]);
      if (start.split(':')[1] == '30') {
        startHour += 0.5;
      }
      let endHour = Number(end.split(':')[0]);
      if (end.split(':')[1] == '30') {
        endHour += 0.5;
      }
      let timeHour = Number(time.split(':')[0]);
      if (time.split(':')[1] == '30') {
        timeHour += 0.5;
      }
      // Is same day
      if (startHour < endHour) {
        return timeHour >= startHour && timeHour <= endHour;
      } else {
        // is next day
        return timeHour >= startHour || timeHour <= endHour;
      }
    },

    dateToString(dt: Date | string): string {
      return dt && dt instanceof Date
        ? dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2)
        : '';
    },

    formatTime(minutes: number): string {
      if (!minutes) {
        return '';
      }
      const time = addMinutes(startOfDay(new Date()), minutes);
      return format(time, 'p', { locale: de });
    },

    openCheckInDate() {
      (this.$refs.checkIn as any).showPopover();
    },

    openCheckOutDate() {
      (this.$refs.checkOut as any).showPopover();
    },
  },
});
