






























import PlayroomPublicService from '@/services/public/PublicPlayroomService';
import Vue from 'vue';
import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import PlayroomBookingCard from '@/components/playrooms/PlayroomBookingCard.vue';
import PlayroomDetailTitlePart from '@/components/playroomDetailParts/PlayroomDetailTitlePart.vue';
import PlayroomDetailImagePart from '@/components/playroomDetailParts/PlayroomDetailImagePart.vue';
import PlayroomDetailKeyFeaturePart from '@/components/playroomDetailParts/PlayroomDetailKeyFeaturePart.vue';
import PlayroomDetailDescriptionPart from '@/components/playroomDetailParts/PlayroomDetailDescriptionPart.vue';
import PlayroomDetailPricePart from '@/components/playroomDetailParts/PlayroomDetailPricePart.vue';
import PlayroomDetailAreaPart from '@/components/playroomDetailParts/PlayroomDetailAreaPart.vue';
import PlayroomDetailFeaturePart from '@/components/playroomDetailParts/PlayroomDetailFeaturePart.vue';
import PlayroomDetailAdditionsPart from '@/components/playroomDetailParts/PlayroomDetailAdditionsPart.vue';
import PlayroomDetailRatingPart from '@/components/playroomDetailParts/PlayroomDetailRatingPart.vue';
import PlayroomDetailHighlightsPart from '@/components/playroomDetailParts/PlayroomDetailHighlightsPart.vue';
import PlayroomDetailLocationPart from '@/components/playroomDetailParts/PlayroomDetailLocationPart.vue';
import PlayroomDetailHostPart from '@/components/playroomDetailParts/PlayroomDetailHostPart.vue';
import PlayroomDetailHouseRulesPart from '@/components/playroomDetailParts/PlayroomDetailHouseRulesPart.vue';
import PlayroomDetailDownloadsPart from '@/components/playroomDetailParts/PlayroomDetailDownloadsPart.vue';
import PlayroomDetailCancellationPart from '@/components/playroomDetailParts/PlayroomDetailCancellationPart.vue';
import PlayroomDetailPrePaymentPart from '@/components/playroomDetailParts/PlayroomDetailPrePaymentPart.vue';
import PlayroomDetailFaqPart from '@/components/playroomDetailParts/PlayroomDetailFaqPart.vue';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  components: {
    PlayroomBookingCard,
    PlayroomDetailTitlePart,
    PlayroomDetailImagePart,
    PlayroomDetailKeyFeaturePart,
    PlayroomDetailDescriptionPart,
    PlayroomDetailPricePart,
    PlayroomDetailAreaPart,
    PlayroomDetailFeaturePart,
    PlayroomDetailAdditionsPart,
    PlayroomDetailRatingPart,
    PlayroomDetailHighlightsPart,
    PlayroomDetailLocationPart,
    PlayroomDetailHostPart,
    PlayroomDetailHouseRulesPart,
    PlayroomDetailDownloadsPart,
    PlayroomDetailCancellationPart,
    PlayroomDetailPrePaymentPart,
    PlayroomDetailFaqPart,
  },
  props: {
    playroomId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      ready: false,
      playroom: new PlayroomDetailDto(),
    };
  },
  metaInfo(): MetaInfo {
    return { title: this.playroom.name };
  },
  methods: {
    loadPlayroom() {
      PlayroomPublicService.getDetail(this.playroomId).then((response) => {
        this.ready = true;
        this.playroom = response.value;
        this.setBreadCrumb();
        this.scrollToHash();
      });
    },
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'PublicPlayroomIndex' }, text: 'Playrooms' },
        { text: this.playroom.name },
      ]);
    },

    scrollToHash() {
      var section = this.$router.currentRoute.hash.replace('#', '');
      if (section) {
        this.$nextTick(() => {
          const element = document.getElementById(section);
          if (element) {
            const yOffset = document.getElementsByTagName('header')[0].offsetHeight;
            const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset - 15;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        });
      }
    },
  },

  mounted() {
    this.loadPlayroom();
  },
});
