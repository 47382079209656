import { differenceInMinutes, differenceInCalendarDays } from 'date-fns';
import PlayroomBookingAdditionOptionsDto from './PlayroomBookingAdditionOptionsDto';

export default class PlayroomBookingOrderDto {
  constructor(playroomId: string) {
    this.playroomId = playroomId;
  }

  playroomId: string;
  checkInDate = '';
  checkInTime = '';
  checkOutDate = '';
  checkOutTime = '';
  countOfGuests = '2';
  bookingType = 'overnight';
  additions = new Array<PlayroomBookingAdditionOptionsDto>();

  get hasDates(): boolean {
    if (this.bookingType == 'overnight') {
      return this.checkInDate != '' && this.checkOutDate != '';
    } else {
      return this.checkInDate != '' && this.checkInTime != '' && this.checkOutTime != '';
    }
  }

  get checkInDateAsDate(): Date {
    return new Date(this.checkInDate + 'T' + this.checkInTime);
  }

  get checkOutDateAsDate(): Date {
    return new Date(this.checkOutDate + 'T' + this.checkOutTime);
  }

  get totalHours(): number {
    return differenceInMinutes(this.checkOutDateAsDate, this.checkInDateAsDate) / 60;
  }

  get totalDays(): number {
    return differenceInCalendarDays(this.checkOutDateAsDate, this.checkInDateAsDate);
  }
}
