









































import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import LoginModal from '@/components/shared/LoginModal.vue';
import MessageToHostModal from '@/components/shared/MessageToHostModal.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  components: { LoginModal, MessageToHostModal },
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  data() {
    return {
      showMessageToHostModal: false,
      showLoginModal: false,
    };
  },
  computed: {
    loggedInAsCustomer(): boolean {
      if (!this.loggedIn) return false;
      const role = this.$store.state.AuthStoreModule.user.role;
      return role == 'Customer';
    },

    loggedIn(): boolean {
      return this.$store.state.AuthStoreModule.status.loggedIn;
    },
  },
  methods: {
    closeMessageToHostModal() {
      this.showMessageToHostModal = false;
    },

    openMessageToHostModal() {
      this.showMessageToHostModal = true;
    },

    openLoginModal() {
      this.showLoginModal = true;
    },

    onUpdateShow() {
      this.showLoginModal = false;
    },
  },
});
