




































































import Vue, { PropType, VueConstructor } from 'vue';
import PlayroomBookingDto from '@/models/PlayroomBookingDto';
import { validationMixin } from 'vuelidate';
import PlayroomBookingOrderDto from '@/models/PlayroomBookingOrderDto';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { addHours, parse } from 'date-fns';

const validations = {
  playroom: {},
  checkinDate: {},
  checkinTime: {},
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [validationMixin, FormaterMixin],
  validations,
  props: {
    playroom: Object as PropType<PlayroomBookingDto>,
    order: {
      type: Object as PropType<PlayroomBookingOrderDto>,
      required: true,
    },
  },
  data() {
    return {
      isCancellationHour: false,
      isCancellationFree: false,
      cancellationTime: null as string | null,
      cancellationPercent: 0,
    };
  },
  watch: {
    order: {
      immediate: true,
      deep: true,
      handler() {
        this.getCancellationTime();
      },
    },
  },
  computed: {
    prepayment(): number {
      if (this.order.bookingType == 'hourly') {
        return Number.parseFloat(this.playroom.prepaymentForHourlyBooking);
      } else {
        return Number.parseFloat(this.playroom.prepaymentForOvernightBooking);
      }
    },
    prepaymentLocalString(): string {
      return this.prepayment.toFixed(2).toString().replaceAll('.', ',');
    },
    hoursToBook(): number {
      const bookingDateTimeString = this.order.checkInDate + ' ' + this.order.checkInTime;
      const bookingDateTime = parse(bookingDateTimeString, 'yyyy-MM-dd hh:mm', new Date());
      const currentDateTime = new Date();
      let seconds = Math.abs(bookingDateTime.getTime() - currentDateTime.getTime()) / 1000;
      return Math.floor(seconds / 3600);
    },
  },
  methods: {
    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },
    getCancellationTime() {
      if (isNaN(this.hoursToBook)) return;
      const cancellationRules = this.playroom.playroomCancellationRules
        .filter((x) => {
          return x.cancellationRulesType.toUpperCase() == this.order.bookingType.toUpperCase();
        })
        .sort((a, b) => {
          return a.percentage - b.percentage;
        })
        .map((obj) => {
          return {
            cancellationRulesType: 1,
            beforeStart: obj.beforeStart,
            percentage: obj.percentage,
            hoursBeforeStart: this.getHoursBeforeStart(obj.beforeStart),
          };
        });
      const filterRules = cancellationRules.filter((x) => this.hoursToBook >= x.hoursBeforeStart);
      if (filterRules && filterRules.length > 0) {
        const filterRule = filterRules[0];
        const checkInDateString = this.order.checkInDate + ' ' + this.order.checkInTime;
        const checkInDate = parse(checkInDateString, 'yyyy-MM-dd hh:mm', new Date());
        const cancellationDate = addHours(checkInDate, -filterRule.hoursBeforeStart);
        const days = Math.floor(this.hoursToBook / 24);
        this.isCancellationHour = days < 1;
        this.isCancellationFree = filterRule.percentage === 0;
        if (this.isCancellationHour) {
          this.cancellationTime = this.transformTime(filterRule.hoursBeforeStart);
        } else {
          this.cancellationTime = this.formatDateTime(cancellationDate);
        }

        if (!this.isCancellationFree) {
          this.cancellationPercent = filterRule.percentage;
        } else {
          this.cancellationPercent = 0;
        }
      } else {
        this.cancellationPercent = 0;
        this.isCancellationFree = false;
      }
    },

    getHoursBeforeStart(key: string): number {
      switch (key) {
        case 'before30Days':
          return 30 * 24;
        case 'before14Days':
          return 14 * 24;
        case 'before5Days':
          return 5 * 24;
        case 'before3Days':
          return 3 * 24;
        case 'before1Days':
          return 24;
        case 'before5hours':
          return 5;
        case 'before3hours':
          return 3;
        case 'before1hours':
          return 1;
        case 'beforeSameDay':
          return 0;
      }
      return 0;
    },
    transformTime(timeNumber: number): string | null {
      if (timeNumber == -1) return null;
      if (timeNumber < 10) return `0${timeNumber}:00`;
      return `${timeNumber}:00`;
    },
  },
});
