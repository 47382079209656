














import PlayroomBookingOrderDto from '@/models/PlayroomBookingOrderDto';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    order: {
      type: Object as PropType<PlayroomBookingOrderDto>,
      required: true,
    },
  },
  computed: {
    isButtonsDisable(): boolean {
      return !(
        (this.isCustomer || this.isNotLoggedIn) &&
        this.order.checkInDate !== '' &&
        this.order.checkOutDate !== '' &&
        this.order.checkInTime !== '' &&
        this.order.checkOutTime !== ''
      );
    },
    isCustomer(): boolean {
      return !this.isNotLoggedIn && this.$store.state.AuthStoreModule.user.role == 'Customer';
    },
    isNotLoggedIn(): boolean {
      return !this.$store.state.AuthStoreModule.status.loggedIn;
    },
    title(): string {
      if (!this.isCustomer && !this.isNotLoggedIn) {
        return this.$t('components.BookingCard.onlyForCustomers').toString();
      }
      if (this.isButtonsDisable) {
        return this.$t('components.BookingCard.reviewCompleteBtnInfo').toString();
      }
      return '';
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('onSubmit');
    },
  },
});
