

























































import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import PlayroomBookingAdditionOptionsDto from '@/models/PlayroomBookingAdditionOptionsDto';
import AdditionalOptionsSummary from '@/components/BookingCardParts/AdditionalOptionsSummary.vue';
import PlayroomBookingOrderDto from '@/models/PlayroomBookingOrderDto';

const validations = {
  internalAdditionOptions: {
    $each: {
      id: {},
      hasChecked: {},
      name: {},
      unit: {},
      forBookingType: {},
      priceForOvernight: {},
      priceForHourly: {},
      isPricePerPiece: {},
      isPricePerTime: {},
    },
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  components: {
    ValidatedCheckbox,
    AdditionalOptionsSummary,
  },
  validations,
  props: {
    additionOptions: {
      type: Array as PropType<PlayroomBookingAdditionOptionsDto[]>,
      required: true,
    },
    order: {
      type: Object as PropType<PlayroomBookingOrderDto>,
      required: true,
    },
  },
  computed: {
    internalAdditionOptions(): PlayroomBookingAdditionOptionsDto[] {
      return this.additionOptions.filter(
        (x) => x.forBookingType.toLowerCase() == this.order.bookingType || x.forBookingType == 'All'
      );
    },
  },
  methods: {
    translationKey(
      addition: { isPricePerPiece: { $model: boolean }; isPricePerTime: { $model: boolean } },
      bookingType: string
    ): string {
      let unitType = 'Price';
      if (addition.isPricePerPiece.$model) {
        unitType += 'PerPiece';
      }
      if (addition.isPricePerTime.$model) {
        unitType += 'PerTime' + bookingType;
      }
      return unitType;
    },

    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },

    minus(additionOption: { hasChecked: { $model: boolean }; unit: { $model: number } }) {
      if (additionOption.unit.$model > 0) {
        additionOption.unit.$model -= 1;
      }
      if (additionOption.unit.$model == 0) {
        additionOption.hasChecked.$model = false;
      }
    },

    plus(additionOption: { hasChecked: { $model: boolean }; unit: { $model: number } }) {
      additionOption.unit.$model += 1;
      if (additionOption.unit.$model > 0) {
        additionOption.hasChecked.$model = true;
      }
    },

    onHasCheckedChange(additionOption: { hasChecked: { $model: boolean }; unit: { $model: number } }) {
      if (additionOption.hasChecked.$model) {
        if (additionOption.unit.$model == 0) {
          additionOption.unit.$model = 1;
        }
      } else {
        additionOption.unit.$model = 0;
      }
    },

    onOk() {
      this.$emit('ok', this.internalAdditionOptions);
    },

    onCancel() {
      this.$emit('cancel');
    },
  },
});
