







































import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import PlayroomImage from '@/models/PlayroomImage';
import Vue, { PropType } from 'vue';
import PlayroomPublicService from '@/services/public/PublicPlayroomService';
import SmCarousel from '@/components/modified/SmCarousel.vue';

export default Vue.extend({
  components: { SmCarousel },
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  data() {
    return {
      showModal: false,
      modalActiveImage: 0,
      images: [] as PlayroomImage[],
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    firstImage(): PlayroomImage {
      return this.images[0];
    },

    otherImages(): PlayroomImage[] {
      return this.images.slice(1, 5);
    },

    showMoreImagesButton(): boolean {
      return this.images.length > 5;
    },

    additionalImageCount(): number {
      if (this.images.length > 5) {
        return this.images.length - 5;
      } else {
        return 0;
      }
    },
    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },
  },
  methods: {
    onClose() {
      this.showModal = false;
    },

    onImageChange(index: number) {
      this.modalActiveImage = index;
    },

    openModal(index: number) {
      this.showModal = true;
      (this.$refs.carousel as any).setItem(index);
    },

    captionTitle(index: number): string {
      return this.images[index].title;
    },

    captionDescription(index: number): string {
      return this.images[index].description;
    },

    loadData() {
      PlayroomPublicService.getImages(this.playroom.id).then(
        (response) => {
          setTimeout(() => {
            this.ready = true;
          }, 1000);
          this.images = response.value.images;
          this.images = this.images.sort((a, b) => a.sortOrder - b.sortOrder);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          console.log('Error:', error);
        }
      );
    },
  },
});
