














































import PlayroomDetailDto, { PlayroomFileViewModel } from '@/models/PlayroomDetailDto';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  computed: {
    showPart(): boolean {
      return (
        this.businessTermsFile != null ||
        this.houseRulesFile != null ||
        this.dataPrivacyPolicyFile != null ||
        this.imprintRulesFile != null
      );
    },
    businessTermsFile(): PlayroomFileViewModel | null {
      const arrFiles = this.playroom.files.filter((f) => {
        return f.type == 'GeneralBusinessTerms';
      });
      return arrFiles.length > 0 ? arrFiles[0] : null;
    },
    houseRulesFile(): PlayroomFileViewModel | null {
      const arrFiles = this.playroom.files.filter((f) => {
        return f.type == 'HouseRules';
      });

      return arrFiles.length > 0 ? arrFiles[0] : null;
    },
    dataPrivacyPolicyFile(): PlayroomFileViewModel | null {
      const arrFiles = this.playroom.files.filter((f) => {
        return f.type == 'DataPrivacyPolicy';
      });

      return arrFiles.length > 0 ? arrFiles[0] : null;
    },
    imprintRulesFile(): PlayroomFileViewModel | null {
      const arrFiles = this.playroom.files.filter((f) => {
        return f.type == 'Imprint';
      });

      return arrFiles.length > 0 ? arrFiles[0] : null;
    },
  },
});
