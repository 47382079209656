










































import PlayroomCustomFeature from '@/models/PlayroomCustomFeature';
import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import PlayroomFeature from '@/models/PlayroomFeature';
import PlayroomPublicService from '@/services/public/PublicPlayroomService';
import Vue, { PropType } from 'vue';

class PlayroomFeatureCategory {
  features: PlayroomFeature[] = new Array<PlayroomFeature>();
  translationKey!: string;
}

export default Vue.extend({
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      ready: false,
      internalPlayroomFeatures: [] as PlayroomFeature[],
      playroomCustomFeatures: [] as PlayroomCustomFeature[],
    };
  },
  computed: {
    playroomFeatures(): PlayroomFeatureCategory[] {
      const playroomFeatureCategories = new Array<PlayroomFeatureCategory>();
      this.internalPlayroomFeatures.forEach((playroomFeature: PlayroomFeature) => {
        var category = playroomFeatureCategories.find(
          (c) => c.translationKey == playroomFeature.categoryTranslationKey
        );
        if (!category) {
          category = new PlayroomFeatureCategory();
          category.translationKey = playroomFeature.categoryTranslationKey;
          playroomFeatureCategories.push(category);
        }
        category.features.push(playroomFeature);
      });
      return playroomFeatureCategories.sort((a, b) => (a.translationKey > b.translationKey && 1) || -1);
    },
  },
  methods: {
    loadData() {
      PlayroomPublicService.getFeatures(this.playroom.id).then(
        (response) => {
          setTimeout(() => {
            this.ready = true;
          }, 1000);
          this.internalPlayroomFeatures = response.value.features;
          this.playroomCustomFeatures = response.value.playroomCustomFeatures;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          console.log('Error:', error);
        }
      );
    },
  },
});
