











































import PlayroomDetailDto from '@/models/PlayroomDetailDto';
import Vue, { PropType } from 'vue';
import PetsAllowedIcon from '@/assets/scss/images/petsAllowed.svg';
import PartyEventAllowedIcon from '@/assets/scss/images/partyEventAllowed.svg';
import PartyEventNotAllowedIcon from '@/assets/scss/images/partyEventNotAllowed.svg';

export default Vue.extend({
  components: { PetsAllowedIcon, PartyEventAllowedIcon, PartyEventNotAllowedIcon },
  props: {
    playroom: Object as PropType<PlayroomDetailDto>,
  },
});
